::-webkit-scrollbar {
  display: none;
}

.custom-btn-clr {
  background-color: #ff0094 !important;
  color: white !important;
}
.custom-btn-clr:hover {
  background-color: rgba(215, 98, 231, 0.1) !important;
  color: #ff0094 !important;
}

.cursor_pointer {
  cursor: pointer;
}

.intro-page {
  padding: 85px 52px;
  width: 561px;
  background-color: white;
  display: grid;
  place-items: center;
}

.intro-page > h3 {
  color: #000000;
  margin-top: 78px;
  font-size: 24px;
  text-align: center;
  font-style: normal;
  line-height: 28.8px;
  font-weight: 400;
  font-variant: normal;
  letter-spacing: normal;
}

.intro-page > h4 {
  color: #000000;
  font-size: 20px;
  text-align: center;
  margin-top: 32px;
  line-height: 24px;
  font-style: normal;
  font-weight: 300;
  font-variant: normal;
  letter-spacing: normal;
}

/* for similar profile  */
.similar-profile-base{
  float: inline-end;
  margin-top: 1px;
}
.similar-profile-base .vb63d9jt {
  height: 24px;

}

.similar-profile-74t4trh3938y {
  width: 100vw;
}

.similar-profile-base .sime9ju702g42s {
  margin-right: 30px;
  background: #00000014;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  font-size: 12px;
  cursor: pointer;
}
