.profile360-modal {
    .form-shashi-enroll {
        .enroll-label {
            font-family: "Azo Sans Lt";
            font-size: 16px;
            line-height: 19.2px;
            text-align: left;
        }

        input {
            outline: none;
            height: 40px;
            border: 0.5px solid black;
            font-size: 16px;
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 14px;
            padding-bottom: 14px;
        }
    }

    .form-shashi-key-setup {
        .rule-label {
            font-family: "Azo Sans Lt";
            font-size: 16px;
            line-height: 19.2px;
        }

        .desc {
            span {
                font-family: "Azo Sans Lt";
            }

            font-size: 16px;
            line-height: 19.2px;
        }

        .email-verified-label {
            font-size: 16px;
            line-height: 19.2px;
        }

        input {
            height: 42px;
            border: 0.5px solid black;
            outline: none;
        }

        .brightness-low {
            filter: brightness(0.8);
        }
    }

    .my-preferences {
        .my-pref-ttle {
            font-family: "Azo Sans Lt";
            font-size: 16px;
            line-height: 18px;

        }

        input {
            font-size: 16px;
            font-weight: 300;
            line-height: 18px;
            outline: none;
            border: none;
        }

        select {
            font-size: 16px;
            font-weight: 300;
            line-height: 18px;
            outline: none;
            border: none;
        }

        .temperature-input {
            .ttle {
                text-align: center;
                color: #a2a2a2;
                font-family: "Azo Sans Lt";
            }

            .btn-txt {
                font-size: 22px !important;
            }

            background: black;
            width: 160px;
            z-index: 1;
            height: 130px;
            top: 0;
            right: 0;
            margin-right: 30px;
            border-radius: 6px;
            color: white;


        }

        .anim-to-dark {
            animation: dark 0.3s ease-in-out;
        }

        .anim-to-light {
            animation: light 0.3s ease-out;
        }

        @keyframes dark {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @keyframes light {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        .color-picker-1 {
            background-color: black;
            border-radius: 7px;
        }

    }

    .new-card {

        input,
        .input-div {
            height: 42px;
            border: 0.5px solid black;
            outline: none;
        }
    }

    .form-btn {
        border: none;
        width: 109.57px;
        height: 40px;
        padding-top: 5px;
        font-size: 17.39px;
        cursor: pointer;
    }

    .form-btn-magenta {
        background-color: #F41095;
        color: white;
    }

    .form-btn-white {
        border: 1px solid black;
        background-color: white;
        color: black;
    }

    .error-text-clr {
        color: rgb(191, 67, 67);
    }

}