.profile-global-search {
    color: black;

    .profile {
        padding: 20px;
        width: 436px;
        height: 634px;
        background-color: white;

        .img {
            width: 286px;
            height: 286px;
            object-fit: cover;
            border-radius: 9999px;
        }

        .matcher {
            height: 67px;
            width: 67px;
            color: white;
            background-color: #F41095;
            border-radius: 999px;

            .big {
                font-family: 'Azo Sans Md';
                font-size: 24.36px;
                line-height: 22px;

                .small {
                    // font-family: "Azo Sans Lt";
                    font-size: 13.4px;
                    line-height: 16.08px;
                }
            }

            .small {
                // font-family: "Azo Sans Lt";
                font-size: 12.18px;
                line-height: 14.62px;
            }

        }

        .vip {
            height: 31.44px;
            width: 68.47px;
            color: white;
            margin-top: auto;
            background-color: #F41095;
            font-size: 17.07px;
            line-height: 20.48px;
        }

        .title-1 {

            font-size: 24.56px;
            line-height: 31.2px;
            text-align: center;
            margin-top: 26.54px;
        }

        .company {
            margin-top: 16px;

            div {
                font-family: "Azo Sans Lt";
                font-size: 20px;
                line-height: 24px;
            }
        }

        .location {
            font-family: "Azo Sans Lt";
            font-size: 16px;
            line-height: 28px;
        }

        .email-phone {
            font-family: "Azo Sans Lt";
            font-size: 16px;
            line-height: 19.2px;
        }
    }

    .ttle {
        font-size: 26px;
        font-weight: 400;
        line-height: 31.2px;
    }

}

.skip-profile-search {
    background: linear-gradient(to left, var(--theme-default) 50%, white 50%);
    background-size: 200%;
    background-position: right center;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: white;
    position: fixed;
    right: 0;
    bottom: 0;
    margin-right: 28px;
    margin-bottom: 28px;
    border-radius: 4px;
    display: flex;
    padding: 3px;
    padding-left: 12px;
    padding-right: 12px;
    gap: 8px;
    font-size: 16px;
    font-family: "Azo Sans Lt";
    animation: spring 5s ease-in-out infinite;
    transition: background-position 0.5s ease;
    z-index: 3;
}

.skip-profile-search:hover {
    animation:none;
    background-position: left center;
    box-shadow: inset 0px 0px 0px 1px var(--theme-default);
    color: var(--theme-default);

}

@keyframes spring {
    0% {
        transform: translate(-1px, 0px);
    }

    5% {
        transform: translate(1px, 0px);
    }

    10% {
        transform: translate(-2px, 0px);
    }

    15% {
        transform: translate(2px, 0px);
    }

    20% {
        transform: translate(-3px, 0px);
    }

    25% {
        transform: translate(3px, 0px);
    }

    30% {
        transform: translate(-2px, 0px);
    }

    35% {
        transform: translate(2px, 0px);
    }

    38% {
        transform: translate(0px, 0px);
    }

    75% {
        transform: translate(0px, 0px);
    }

    100% {
        transform: translate(0px, 0px);
    }


}