$gap-values: 2px, 4px, 6px, 8px, 9px, 10px, 12px, 16px, 17px, 18px, 20px, 24px, 26px, 30px;
$margin-padding-values: 1px, 2px, 3px, 4px, 5px, 6px, 8px, 9px, 10px, 11px, 12px, 13px, 14px, 15px, 16px, 17px, 18px, 20px, 21px, 22px, 23px, 24px, 25px, 26px, 27px, 30px, 35px, 39px, 40px, 41px, 49px, 69px, 90px, 93px, ;
$grid-col-values: 1, 2, 3, 4, 5, 6, 7, 8;
$height-value: 13px, 14px, 18px, 30px, 42px, 15px, 20px, 23px, 24px, 40px, 45px, 56px, 60px, 64px;
$width-value: 14px, 18px, 24px, 30px, 120px, 212px, 330px, 394px, 440px, 450px, 500px, 576px, 616px;

.profile-360 {
    .profile-360-base {
        transition-duration: 0.5s;
        padding: 15px;
        padding-top: 30px;
    }

    .radius-full {
        border-radius: 999px;
    }

    .underline {
        border-bottom: solid black 0.3px;
    }

    .flex {
        display: flex;
    }

    .text-center {
        text-align: center;
    }
    .text-end{
        text-align: end;
    }

    .justify-between {
        justify-content: space-between;
    }

    .justify-center {
        justify-content: center;
    }

    .justify-end {
        justify-content: end;
    }

    .w-full {
        width: 100%;
    }

    .items-center {
        align-items: center;
    }

    .items-start {
        align-items: start;
    }

    .items-end {
        align-items: end;
    }

    .flex-wrap {
        flex-wrap: wrap;
    }

    .flex-col {
        flex-direction: column;
    }

    .grid {
        display: grid;
    }

    .left-0 {
        left: 0;
    }

    .right-0 {
        right: 0;
    }

    .whitespace-nowrap {
        white-space: nowrap;
    }

    @each $col in $grid-col-values {
        .grid-col-#{$col} {
            grid-template-columns: repeat($col, minmax(0, 1fr));
        }
    }

    .grid-cols-none {
        grid-template-columns: none;
    }


    @each $gap in $gap-values {
        .gap-#{$gap} {
            gap: $gap;
        }
    }


    @each $height in $height-value {
        .h-#{$height} {
            height: $height;
        }
    }

    @each $width in $width-value {
        .w-#{$width} {
            width: $width;
        }
    }

    .w-fit {
        width: fit-content;
    }

    .ml-auto {
        margin-left: auto;
    }

    .mr-auto {
        margin-right: auto;
    }

    .mt-auto {
        margin-top: auto;
    }

    .mb-auto {
        margin-bottom: auto;
    }

    @each $margin in $margin-padding-values {
        .mb-#{$margin} {
            margin-bottom: $margin;
        }

        .mr-#{$margin} {
            margin-right: $margin;
        }

        .ml-#{$margin} {
            margin-left: $margin;
        }

        .mt-#{$margin} {
            margin-top: $margin;
        }

        .m-#{$margin} {
            margin: $margin;
        }
    }

    @each $padding in $margin-padding-values {
        .pb-#{$padding} {
            padding-bottom: $padding;
        }

        .pr-#{$padding} {
            padding-right: $padding;
        }

        .pl-#{$padding} {
            padding-left: $padding;
        }

        .pt-#{$padding} {
            padding-top: $padding;
        }

        .p-#{$padding} {
            padding: $padding;
        }
    }

    .custom-button {
        border: none;
        outline: none;
        color: #F41095;
        font-size: 10px;
        line-height: 12px;
        padding-top: 6px;
        padding-bottom: 3px;
        border: 1px solid #F41095;

    }

    .custom-button:hover {
        filter: brightness(1.3);
        filter: drop-shadow(2px 2px 2px #ff00954d)
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .cursor-not-allowed {
        cursor: not-allowed !important;
    }

    .relative {
        position: relative;
    }

    .absolute {
        position: absolute;
    }

    .sticky {
        position: sticky;
    }

    .bottom-0 {
        bottom: 0;
    }

    .profile360-modal {
        background-color: white;
        padding: 20px;
        color: black;
        transition: height 4s;

        .title {
            font-family: 'Azo Sans Md';
            font-size: 18px;
            line-height: 21.6px;
        }
    }

    @media(max-width:1439px) {
        .profile-Preference-wrap {
            flex-direction: row-reverse;
        }

        .purposeful-notes-wrapper {
            display: flex;
            justify-content: center;
            gap: 26px;
            flex-wrap: wrap;

            .PurposefulEngagement {
                width: 493px;
            }

            .encloser {
                width: 399px;
            }
        }

    }

    @media(max-width:977px) {
        .purposeful-notes-wrapper {
            flex-direction: column;

            .PurposefulEngagement {
                width: 436px;
            }

            .encloser {
                width: 436px;
            }
        }
        .user-profile{
           height: fit-content;
        }
    }

    @media(max-width:575px) {
        .profile-360-base {
            padding-top: 15px;
        }
    }

    .bg-light-gay {
        background-color: #F4F4F4;
    }

    .animation-Icon-black {
        animation: frame-icon 0.9s ease-out;
        z-index: 2;
    }
    .animation-Icon-black:hover{
       filter: drop-shadow(1px 1px 2px  rgb(179, 179, 179));
    }

    @keyframes frame-icon {
        0% {
            opacity: 0.1;
        }

        50% {
            opacity: 0.6;
        }

        100% {
            opacity: 1;
        }
    }

}

.tooltip .tooltip-inner {
    background: #2c2c2c85 !important;
    color: white !important;
    backdrop-filter: blur(4px) !important;
    font-size: 12px !important;
}

.profile-360-modal-dialog {
    max-width: none !important;
    display: flex;
    justify-content: center;
}

.profile-360-modal-dialog .modal-content {
    width: auto !important;
}