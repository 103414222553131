.hotel-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.hotel-card {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.hotel-image {
  width: 100%;
  height: 30vh;
}

.hotel-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
}

.hotel-info h2 {
  margin: 0;
}

.hotel-button {
  background-color: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #000;
}

.hotel-button:hover {
  color: #007bff;
}

.pointer {
  cursor: pointer;
}
