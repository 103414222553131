.encloser {
    color: black;
    width: 436px;

    .encloser-title {
        font-family: "Azo Sans Md";
        font-size: 18px;
        line-height: 21.6px;
    }

    .encloser-description {
        font-family: "Azo Sans Lt";
        font-size: 16px;
        line-height: 19.2px;
        max-height: 0px;
        opacity: 0;
    }

    .opacity-1 {
        opacity: 1;
    }

    .while-open {
        max-height: 800px !important;
    }


    .list-parent li {
        position: relative;
        padding-left: 18px;
    }

    .list-parent li::before {
        content: "⥁";
        position: absolute;
        left: 0;
        font-size: 20px;
        color: black;
    }
}