.current-reservations {
    // css for current reservation section -->>
    width: 399px;
    max-height: 377.57px;
    transition:2s;
    background-color: #FFFFFF;
    padding: 20px;
    overflow: hidden;
    padding-bottom: 7px;
    color: black;

    .title {
        font-family: 'Azo Sans Md';
        font-size: 18px;
        line-height: 21.6px;
        text-align: left;
    }

    .key {
        font-family: "Azo Sans Lt";
        font-size: 12px;
        line-height: 14.4px;
    }

    .value {
        font-size: 14px;
        line-height: 16.8px;
        max-width: 140px;
    }

    .express-checkin {
        font-family: "Azo Sans Lt";
        font-size: 12px;
    }
}

.current-reservations-loading {
    max-height: 600px;
}