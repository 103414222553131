.preferences {
    // css for current reservation section -->>
    width: 399px;
    min-height: 479px;
    background-color: #FFFFFF;
    padding: 20px;
    padding-top: 21px;
    color: black;

    .title {
        font-family:'Azo Sans Md';
        font-size: 18px;
        line-height: 21.6px;
        text-align: left;
    }

    .label-15 {
        font-family:"Azo Sans Lt";
        font-size: 15px;
        line-height: 18px;
    }
    .label-14 {
        font-family:"Azo Sans Lt";
        font-size: 14px;
        line-height: 16.8px;
    }

    .value {
        font-size: 15px;
        line-height: 18px;
        text-align: end;
    }

}