.user-profile {
    // css for current reservation section -->>
    width: 493px;
    height: 100%;
    background-color: #FFFFFF;
    padding: 20px;
    padding-top: 21px;
    padding-bottom: 0px;
    color: black;

    .profile {
        padding-left: 20px;
        padding-right: 20px;

        .img {
            width: 286px;
            height: 286px;
            object-fit: cover;
            border-radius: 9999px;
        }

        .vip {
            height: 31.44px;
            width: 68.47px;
            color: white;
            margin-top: auto;
            background-color: #F41095;
            font-size: 17.07px;
            line-height: 20.48px;
        }

        .round-indic {
            border: solid #F41095 1.5px;
            border-radius: 999px;
            overflow: hidden;
            height: 63px;
            width: 63px;

            .title-2 {
                font-family: 'Azo Sans Md';
                font-size: 13px;
                line-height: 24px;
            }

            .dec {
                font-size: 8px;
                line-height: 9.6px;
            }
        }

        .title-1 {

            font-size: 26px;
            line-height: 31.2px;
            text-align: center;
            margin-top: 26.54px;
        }

        .google {
            margin-top: 6px;

            div {
                font-family: "Azo Sans Lt";
                font-size: 20px;
                line-height: 24px;
            }
        }

        .location {
            font-family: "Azo Sans Lt";
            font-size: 16px;
            line-height: 28px;
        }
    }

    .email-or-phone {
        .input-base {
            border: solid #FF0000ed 1px;
            width: 212px;
            height: 36px;
            background-color: rgba(255, 0, 0, 0.05);
        }

        .input {
            width: 100%;
            height: 100%;
            font-family: "Azo Sans Lt";
            font-size: 14px;
            line-height: 16.8px;
            padding: 12px;
            border: none;
            outline: none;
            background-color: #00000000;
        }

        .values-2 {
            font-family: "Azo Sans Lt";
            font-size: 16px;
            line-height: 19.2px;
        }

        .react-tel-input {
            width: 82px !important;
            height: 22px !important;

            .country-list {
                display: flex;
                flex-direction: column;
            }

            .flag-dropdown {
                border: solid #ff00006a 1px;
                background-color: #00000000 !important;


                // border: none !important;
                // background-color: #d3b8b6 !important;
                border-radius: 0 !important;

                .open .selected-flag {
                    border-radius: 0 !important;
                }
            }
        }

        .phone-12 {
            width: 100%;
            height: 20px;
            font-family: "Azo Sans Lt";
            font-size: 14px;
            line-height: 16.8px;
            padding-left: 8px;
            border: none;
            outline: none;
            background-color: #00000000;
            margin-top: 9px;
        }
    }

    .ai-platform {
        .ai-title {
            font-family: 'Azo Sans Md';
            font-size: 18px;
            line-height: 21.6px;
        }

        .ai-label {
            font-family: "Azo Sans Lt";
            font-size: 16px;
            line-height: 19.2px;

        }

        .content-1 {
            font-family: "Azo Sans Lt";
            font-size: 16px;
            line-height: 19.2px;
        }
    }

    .weather {
        .temperature {
            font-family: "Azo Sans Lt";
            font-size: 14px;
            line-height: 16.8px
        }

        .day {
            font-family: "Azo Sans Lt";
            font-size: 10px;
            line-height: 12px;
        }

        .subTititle {
            font-family: "Azo Sans Lt";
            font-size: 12px;
            line-height: 14.4px
        }

        .icon {
            width: 34px;
            height: 34px;
        }
    }

}