::-webkit-scrollbar {
    width: 4px  !important; /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 6px; /* Optional: to round the corners of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}


.flexerContainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
}
.header{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}
.bbb{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
}
.btnOutlined{
    background-color: #d762e726;
    color:#FE0097;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
    border: none;
}
.btnDisabled{
    background-color: #d762e726;
    color:#FE0097;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
    border: none;
    opacity: 0.9;
    cursor: not-allowed;
   
}

.iot_scrollbars{
    height: 160vh !important;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-color: #666 white; 
     scrollbar-width: thin;
     margin-top: 16px;
}
