.PurposefulEngagement {
    // css for current reservation section -->>
    width: 436px;
    height: fit-content;
    background-color: #FFFFFF;
    padding: 20px;
    color: black;
    margin-bottom: 20px;

    .title-base-div {
        padding-bottom: 12px;

        .title {
            font-family: 'Azo Sans Md';
            font-size: 18px;
            line-height: 21.6px;
            text-align: left;
        }
    }



    .Engagements {
        padding-top: 24px;

        .heading {
            font-size: 16px;
            line-height: 19.2px;
            font-family: 'Azo Sans Md';
        }

        .view-more {
            border-bottom: 0.8px solid black;
            height: fit-content;
        }

        .view-more:hover {
            color: #F41095;
            border-bottom: 0.8px solid #F41095;
        }

        .drop-content {
            overflow: hidden;
            height: 45px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 11px;
            padding-bottom: 11px;
            background-color: #F4F4F4;
            color: black;

            .drop-label {
                font-size: 14px;
                line-height: 16.8px;
            }
        }
    }


    .text-300 {
        font-family: "Azo Sans Lt";
        font-size: 12px;
        line-height: 14.4px;
    }

    .history {
        color: #F41095;
    }
}

.profile360-modal {
    .room-upgrade {
        .desc {
            font-family: "Azo Sans Lt";
            font-size: 16px;
            line-height: 19.2px;
        }

        .type-code {
            font-size: 16px;
            line-height: 19.2px;
        }

        .list-parent li {
            position: relative;
            padding-left: 13px;
            font-family: "Azo Sans Lt";
            line-height: 16.8px;
        }

        .list-parent li::before {
            content: "+";
            position: absolute;
            left: 0;
            font-size: 13px;
            color: black;
        }

        .table-parent {
            thead {
                font-size: 14px;
                line-height: 16.8px;
                font-family: "Azo Sans Lt";
            }

            tbody {
                font-size: 16px;
                line-height: 19.2px;
            }
        }
    }

    .packages {
        .desc {
            font-family: "Azo Sans Lt";
            font-size: 16px;
            line-height: 19.2px;
        }

        .pkg-name {
            font-size: 16px;
            font-family: "Azo Sans";
            line-height: 19.2px;
        }

        .title {
            font-family: "Azo Sans";
            line-height: 19.2px;
            font-size: 15px;
        }

        .list-parent li {
            position: relative;
            padding-left: 13px;
            font-family: "Azo Sans Lt";
        }

        .list-parent li::before {
            content: "+";
            position: absolute;
            left: 0;
            font-size: 13px;
            color: black;
        }
    }

    .services {
        .desc {
            font-family: "Azo Sans Lt";
            font-size: 16px;
            line-height: 19.2px;
        }

        .pkg-name {
            font-size: 16px;
            font-family: "Azo Sans";
            line-height: 19.2px;
        }

        .title {
            font-family: "Azo Sans";
            line-height: 19.2px;
            font-size: 15px;
        }

        input {
            padding: 6px !important;
            font-size: 14px;
            width: 100px !important;
        }
    }

    .purposeful-history {

        .drop-content {
            overflow: hidden;
            height: 45px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 11px;
            padding-bottom: 11px;
            background-color: #F4F4F4;
            color: black;

            .drop-label {
                font-size: 14px;
                line-height: 16.8px;
            }
        }

    }

    .bubble-selection {
        .ttl-base {
            overflow: hidden;
            border: 1px solid rgb(166, 166, 166);
            width: 46px;
            height: 46px;

            .ttle-1 {
                font-size: 12px;
            }

            .ttle-2 {
                font-size: 9px;
            }
        }

        .ttl-base-select {
            border: 1px solid var(--theme-default);
        }

    }

    .custom-button:hover {
        filter: brightness(1.3) !important;
        filter: drop-shadow(2px 2px 2px #0000004d) !important
    }
}